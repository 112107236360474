<template>
  <section class="section container">
    <div class="columns is-centered box">
      <div class="column is-5">
        <form v-on:submit.prevent="recovery">
          <b-field label="Login" v-if="!$route.params.name || $route.params.name === 'false'">
            <b-input placeholder="Login" type="text" v-model="login" required></b-input>
          </b-field>

          <b-field label="Nova senha" v-else>
            <b-input placeholder="Nova senha" type="password" v-model="password" required></b-input>
          </b-field>

          <button class="button is-info is-fullwidth">Recuperar</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Axios from "axios";

const domainUrl = process.env.VUE_APP_DOMAIN_URL;

export default {
  data() {
    return {
      login: "",
      password: ""
    };
  },
  methods: {
    recovery() {
      // ?uid=${this.login}&url=${domainUrl}
      if (!this.password) {
        Axios.post(
          `/v1/recovery-password`,
          { username: this.login, link: domainUrl }
        )
          .then(data => {
            if (data.data) {
              this.$router.push({ path: "/" });
              this.$buefy.dialog.alert(
                "Por favor, verifique sua conta de e-mail para recuperar sua senha!"
              );
            }
          })
          .catch(erro => {
            console.error(erro);
            this.$buefy.dialog.alert("Usuário inválido!");
          });
      } else {
        this.newPass();
      }
    },
    newPass() {
      Axios.put(
        `/v1/recovery-password`,
        { recoveryCode: this.$route.params.name, password: this.password }
      )
        .then(data => {
          if (data.data) {
            this.$buefy.notification.open({
              message: "Senha alterada com sucesso!",
              type: "is-success"
            });
            this.$router.push({ path: "/login" });
          }
        })
        .catch(erro => {
          console.error(erro);
          this.$buefy.dialog.alert("Email inválido ou expirado!");
        });
    }
  }
};
</script>

<style scoped lang="scss">
section {
  height: 100%;

  .box {
    height: 100%;
    align-items: center;
    padding: 10%;
    form {
      width: 100%;
    }
  }
}
</style>
